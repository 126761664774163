import React from 'react';

const Banner = ({
  bannerType, h1, paragraph, paragraph2,
}) => (
  <section id="banner" className={`major ${bannerType}`}>
    <div className="inner">
      <header className="major">
        <h1>{h1}</h1>
      </header>
      <div className="content">
        <p>{paragraph}</p>
        <ul className="actions">
          <li><a href="#contact-us" className="button next scrolly">Gauti pasiūlymą</a></li>
        </ul>
      </div>
      <div className="content">
        <p>{paragraph2}</p>
      </div>
    </div>
  </section>
);

export default Banner;
